import React, { Component } from 'react';
import { Link } from "@reach/router";
import { firstCharUpper } from '../helpers';
import axios from 'axios';

import './Equipment.css';

class Equipment extends Component {

  constructor() {
    super();
    this.state = {
      refreshing: false,
      wcLink: '',
      wcImages: []
    };
  }

  componentWillMount() {
    console.log('get woocomm');
    axios.get(`/.netlify/functions/getwoocomm/getwoocomm.js?sku=${this.props.stockNumber}`)
      .then((resp) => {
        console.log(resp.data);
        const wcImages = [];
        resp.data[0].images.forEach((img) => {
          wcImages.push(img.src)
        });
        this.setState({
          wcLink: resp.data[0].permalink,
          wcImages: wcImages
        })
      })
  }

  refreshEquipment = event => {
    // console.log(this.props.stockNumber);
    this.setState({
      refreshing: true
    })
    axios.get(`/.netlify/functions/updateEquipment/updateEquipment.js?sku=${this.props.stockNumber}`)
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          refreshing: false
        });
      })
  }

  render() {
    const machine = this.props.machines.filter(machine => String(machine.stockNumber) === this.props.stockNumber);
    // const equip = Object.values(machine)[0];
    return (
      <section className="pad-y">
        <div className="site-width">
          {machine.map((equip, index) => {
            const name = `${equip.modelYear} ${equip.manufacturer} ${equip.model}`;
            const location = `${firstCharUpper(String(equip.city).toLowerCase())}, ${equip.state_province}`;

            return (
              <div key={`${equip.stockNumber}-${index}`} className="singleEquipment">
                <div className="singleEquipment--content pad-y">
                  <h2>{name}</h2>
                  <p>{equip.stockNumber}</p>
                  <p>{location}</p>
                  <a className="btn mar-r--least" href={`https://www.machinefinder.com/ww/en-US/machines/${equip.id}`} target="_blank" rel="noopener noreferrer">View on MF.com</a>
                  <a className="btn mar-r--least" href={this.state.wcLink} target="_blank" rel="noopener noreferrer">View on RFE.com</a>
                  <button className="btn" onClick={this.refreshEquipment}>Refresh Equipment</button>
                </div>
                <div className="equip-comparison">
                  <div className="mf-equip">
                    <h2>MF Pro Pictures</h2>
                    {equip.images[0].image.map((img, index) => {
                      return (
                        <p key={String(img.filePointer)}>
                          <img src={String(img.filePointer)} alt={`${equip.stockNumber}-${index}`} />
                        </p>
                      )
                    })}
                  </div>
                  <div className="wc-equip">
                    <h2>RFE Pictures</h2>
                    {this.state.wcImages.map((img, index) => {
                      return (
                        <p key={img}>
                          <img src={img} alt={`${equip.stockNumber}-${index}`} />
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>
            )

          })}
        </div>
      </section>
    )

  }
}

export default Equipment;