import React, { Component } from 'react';
import './Machines.css';
import { Link } from "@reach/router";
import { firstCharUpper } from '../helpers';

class Machines extends Component {
  render() {
    const { loading, machines } = this.props;

    if (loading) {
      return (
        <section className="pad-y">
          <div className="site-width has-text-center">
            <img src="https://www.reynoldsfarmequipment.com/wp-content/themes/rfe/dist/img/spinner.svg" className="spinner" alt="Loading..." />
          </div>
        </section>
      );
    } else {
      return (
        <section className="pad-y">
          <div className="site-width">
            <ul className="list--machines">
              {machines.map(machine => {
                  
                  const name = `${machine.modelYear} ${machine.manufacturer} ${machine.model}`;
                  const location = `${firstCharUpper(String(machine.city).toLowerCase())}, ${machine.state_province}`;
                  let img;
                  if ((machine.images[0].image) && (machine.images[0].image.length > 0)) {
                    img = String(machine.images[0].image[0].filePointer);
                  } else {
                    img = 'http://fillmurray.com/100/100';
                  }

                  return (
                    <li key={machine.id} className="card--machine">
                      <div className="card--image">
                        <Link to={`/details/${machine.stockNumber}`} >
                          <img src={img} alt={name} />
                        </Link>
                      </div>
                      <div className="card--content">
                        <h3><Link to={`/details/${machine.stockNumber}`} >{name}</Link></h3>
                        <p>{machine.stockNumber}</p>
                        <p>{location}</p>
                      </div>
                    </li>
                  )
                  
                }
              )}
            </ul>
          </div>
        </section>
      );
    }

  }
}

export default Machines;
