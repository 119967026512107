import React, { Component } from 'react';
import axios from 'axios';
import { parseString } from 'xml2js';
import { Router, Link } from "@reach/router";

import Machines from './components/Machines';
import Equipment from './components/Equipment';

import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      machines: []
    };
  }

  componentDidMount() {
    
    axios.get('/.netlify/functions/getmachinefinder/getmachinefinder.js')
      .then((resp) => {
        parseString(resp.data, async (err, result) => {
          const sortedMachines = result.machines.machine.sort((a,b) => {
            return new Date(b.createdTimestamp) - new Date(a.createdTimestamp);
          });
          this.setState({
            loading: false,
            machines: sortedMachines
          })
        });
      });

  }

  render() {
    return (
      <div>
        <header className="app-header">
          <div className="site-width">
            <Link to={`/`} >
              <h1>
                MFPro Feed
              </h1>
              <p></p>
            </Link>
          </div>
        </header>
        <main>
          <Router>
            <Machines path="/" loading={this.state.loading} machines={this.state.machines} />
            <Equipment path="/details/:stockNumber" machines={this.state.machines} />
          </Router>
        </main>
      </div>
    );
  }
}

export default App;
